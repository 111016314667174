import { useTranslate } from '@tolgee/react';
import Container from '@amf/shared/components/layout/Container';
import IconAppStore from '@amf/shared/icons/IconAppStore';
import IconGooglePlay from '@amf/shared/icons/IconGooglePlay';
import { PartnerFragment } from '@amf/shared/types/graphql';
import FooterPartners from '@amf/shared/components/layout/FooterPartners';

type Props = {
  partners: PartnerFragment[];
};

export default function Footer({ partners }: Props) {
  const { t } = useTranslate();
  return (
    <div className='Footer'>
      <Container>
        <FooterPartners partners={partners} />
        <div className='Footer__row'>
          <div className='Footer__column'>
            <h2>{t('footer.billing.title')}</h2>
            <address>
              Malý fotbal Olomouc, z.s.
              <br />
              Střední novosadská 202/48
              <br />
              779 00 Olomouc - Nové Sady
            </address>
            <p>IČ: 69210926</p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.contact.title')}</h2>
            <address>
              Malý fotbal Olomouc, z.s.
              <br />
              Střední novosadská 202/48
              <br />
              779 00 Olomouc - Nové Sady
            </address>
            <p>
              <a href='mailto:predseda@mfolomouc.cz'>predseda@mfolomouc.cz</a>
              <br />
              <a href='tel:+420603226907'>+420 603 226 907</a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.social.title')}</h2>
            <p>
              <a
                href='https://www.facebook.com/people/Malý-fotbal-Olomouc/100024207203631/'
                target='_blank'
                rel='noreferrer'
              >
                Facebook
              </a>
              <br />
              <a
                href='https://www.instagram.com/malyfotbalolomouc'
                target='_blank'
                rel='noreferrer'
              >
                Instagram
              </a>
              <br />
              <a
                href='https://www.youtube.com/channel/UCmKSbl3fCdwxQhyKpKsPktw'
                target='_blank'
                rel='noreferrer'
              >
                Youtube
              </a>
            </p>
          </div>
        </div>
        <div className='Footer__apps'>
          <h2>{t('footer.apps.title')}</h2>
          <div className='Footer__appsGrid'>
            <a
              href='https://apps.apple.com/vn/app/mal%C3%BD-fotbal/id1665660869?uo=2'
              target='_blank'
              rel='noreferrer'
            >
              <IconAppStore />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.amf&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <IconGooglePlay />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
